import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import "./footer.styl"

// TODO totopVisible on document.documentElement.scrollTop > 300

export default () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "schafgarbe.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div id="footer">
      <div className="entry impressum">
        <Link className="text" to="/impressum">
          IMPRESSUM
        </Link>
      </div>

      <div className="totop" href="#" uk-scroll="">
        <p>NACH OBEN</p>
      </div>

      <div className="entry">
        <span uk-icon="icon: clock; ratio: 1.5" />
        <p className="text">&Ouml;FFNUNGSZEITEN</p>
      </div>
      <div
        className="info"
        uk-dropdown="pos: top-right; delay-hide: 150; offset: 30; mode: click;"
      >
        <p style={{ whiteSpace: "pre" }}>
          <b>&Ouml;ffnungszeiten</b> <br />
          Mo-Fr: 08.00-18.00 <br />
          Sa: 08.00-12.00
        </p>
      </div>

      <div className="entry">
        <span uk-icon="icon: mail; ratio: 1.5" />
        <p className="text">KONTAKT</p>
      </div>
      <div
        className="info"
        uk-dropdown="pos: top-center; delay-hide: 150; offset: 30; mode: click;"
      >
        <p style={{ whiteSpace: "pre" }}>
          <b>LEBENSKREIS APOTHEKE KG</b> <br />
          Tischlerstra&szlig;e 15 <br />
          8740 Zeltweg, &Ouml;sterreich <br />
          Tel.: <a href="tel:+43357724255"> +43 3577/242 55 </a> <br />
          <a href="mailto:info@lebenskreis-apotheke.at">
            info@lebenskreis-apotheke.at
          </a>
        </p>
      </div>

      <Img fluid={data.placeholderImage.childImageSharp.fluid} className="footer-img"/>
    </div>
  )
}
