import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
// import uikit from 'uikit';
// import icons from 'uikit/dist/js/uikit-icons.min';

import "../assets/uikit-3.1.8/css/uikit.css"
import "../assets/global.styl"
import "./default.styl"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

const DefaultLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const uikit = require("uikit")
      const icons = require("uikit/dist/js/uikit-icons")

      uikit.use(icons)
    }
  })

  return (
    <div className="default">
      <Helmet>
        <link
          data-n-head="true"
          rel="stylesheet"
          href="https://use.typekit.net/jko1zly.css"
        />
      </Helmet>

      <Link id="logo-top" to="/">
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      </Link>
      <Navbar />
      {children}

      <div id="copyright">
        <p>© Lebenskreis Apotheke {new Date().getFullYear()}</p>
      </div>
      <Footer />
    </div>
  )
}

export default DefaultLayout
